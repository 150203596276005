import React, { memo, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  ConnectivityResponse,
  ConnectivityStatus,
  DecoderResponseError,
  DecoderResponseSuccess,
  InteractionResponse,
  PrismaSDK,
  ProgressResponse,
  Subscription,
} from "@soulmade/prismaid";
import SDKSingleton from "./SDK";
import { SwipeData } from "./SwipeData";

import success from "../../assets/sounds/crowd-scream-goal_Myqs-u4_.mp3";
import error from "../../assets/sounds/crowd-scream-oh-no_fkm3Zu4_.mp3";
import kick from "../../assets/sounds/FootballPenaltyKickNostad2_short.mp3";
import stadium from "../../assets/sounds/FootballNoise_-25dB.mp3";

import Sound from "../common/sound/Sound";
import Goalie, { AnimationType } from "./goalie/Goalie";
import CardField from "./goalie/CardField";
import Display, { DisplayText } from "./goalie/Display";

type Props = {
  scaleFactor: number;
};

let progressSubject: Subscription | undefined;
let connectivitySubject: Subscription | undefined;
let detectionSuccessSubject: Subscription | undefined;
let detectionErrorSubject: Subscription | undefined;
let interactionSubject: Subscription | undefined;
let sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
let isSuccessful = false;
let isComplete = false;

function SwipeField(props: Props) {
  const kickSound = useRef(new Sound(kick));
  const errorSound = useRef(new Sound(error));
  const successSound = useRef(new Sound(success));
  const stadiumAudioRef = useRef<HTMLAudioElement | any>();

  const [redirect, setRedirect] = useState("");
  const [currentAnimation, setCurrentAnimation] = useState(AnimationType.None);
  const [currentDisplayText, setCurrentDisplayText] = useState(DisplayText.None);




  useEffect(() => {
    SwipeData.update((s) => {
      s.networkStatus = ConnectivityStatus.ok;
    });
    sdk.resume()
    isSuccessful = false
    progressSubject = sdk.getProgressSubject().subscribe((response: ProgressResponse) => {
      console.log("*) progress:", response.progress);

      SwipeData.update((s) => {
        s.progress = response.progress;
      });
    });

    connectivitySubject = sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      SwipeData.update((s) => {
        s.networkStatus = response.status;
      });
    });

    detectionSuccessSubject = sdk.getDetectionSuccessSubject().subscribe((response: DecoderResponseSuccess) => {
      console.log("*) detection success:", response.codeId);
      if (redirect) {
        return;
      }
      if (response.codeId === "soccer" && response.direction == 'up') {
        handleKick();
        isSuccessful = true;
      }
    });

    detectionErrorSubject = sdk.getDetectionErrorSubject().subscribe((response: DecoderResponseError) => {
      console.log("*) detection error:", currentDisplayText);
      if (isSuccessful || isComplete) {
        return;
      }
      setCurrentDisplayText(DisplayText.TryAgain);
      setTimeout(() => {
        if(!isSuccessful && !isComplete){
          setCurrentDisplayText(DisplayText.Swipe);
        }
      }, 1000);
    });

    interactionSubject = sdk.getInteractionSubject().subscribe((response: InteractionResponse) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      if (currentDisplayText === DisplayText.Goal || isSuccessful == true) {
        return;
      }

      switch (response.event) {
        case "started":
          setCurrentDisplayText(DisplayText.Swipe);
          isComplete = false;
          SwipeData.update((s) => {
            s.showDot = true;
          });
          break;
        case "changed":
          break;
        case "complete":
          SwipeData.update((s) => {
            s.showDot = false;
          });
          SwipeData.update((s) => {
            s.progress = 0;
          });
          setCurrentDisplayText(DisplayText.PlaceCard);
          isComplete = true;
          break;
        default:
          break;
      }
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      sdk.attachToElement(screen);
    }

    setTimeout(() => {
      setCurrentDisplayText(DisplayText.PlaceCard);
    }, 400);
    setTimeout(() => {
      stadiumAudioRef.current?.play();
      setCurrentAnimation(AnimationType.Base);
    }, 1000);

    return () => {
      if (progressSubject !== undefined) {
        progressSubject.unsubscribe();
      }
      if (connectivitySubject !== undefined) {
        connectivitySubject.unsubscribe();
      }
      if (detectionSuccessSubject !== undefined) {
        detectionSuccessSubject.unsubscribe();
      }
      if (detectionErrorSubject !== undefined) {
        detectionErrorSubject.unsubscribe();
      }
      if (interactionSubject !== undefined) {
        interactionSubject.unsubscribe();
      }
    };
  }, []);

  const handleKick = () => {
    // getGoaliePosition
    let goaliePosition = getGoaliePosition();
    // getKickResult -> GoaliePosition and ballPosition intersect
    if (isKickSuccessFull(goaliePosition)) {
      handleSuccess();
    } else {
      handleError();
    }
  };

  const getGoaliePosition = () => {
    let goalie = document.getElementById("goalie");
    if (goalie) {
      let pos = goalie.getBoundingClientRect();
      return { left: pos.left, right: pos.right };
    }
    return { left: 0, right: 0 };
  };

  const isKickSuccessFull = (goaliePosition: { left: number; right: number }) => {
    let ballPosition = window.innerWidth / 2;
    if (goaliePosition.left <= ballPosition && ballPosition <= goaliePosition.right) {
      return false;
    }
    return true;
  };

  const handleSuccess = () => {
    sdk.pause()
    setCurrentAnimation(AnimationType.Goal);
    kickSound.current.play();

    setTimeout(() => {
      setCurrentDisplayText(DisplayText.Goal);
      var btn = document.getElementById("startConfetti") as any;
      btn.onclick.apply(btn);

      successSound.current.play();
    }, 750);

    // redirect
    setTimeout(() => {
      var btn = document.getElementById("removeConfetti") as any;
      btn.onclick.apply(btn);

      setRedirect("/success");
    }, 4000);
  };

  const handleError = () => {
    // goalieAnimation
    setCurrentAnimation(AnimationType.NoGoal);

    kickSound.current.play();

    setTimeout(() => {
      // displayText
      setCurrentDisplayText(DisplayText.Missed);

      errorSound.current.play();
    }, 750);

    setTimeout(() => {
      handleReset();
    }, 3000);
  };

  const handleReset = () => {
    // reset displayText
    setCurrentDisplayText(DisplayText.TryAgain);

    // reset animation
    setCurrentAnimation(AnimationType.Base);
    sdk.resetManual()
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      setTimeout(() => {
        if (document.visibilityState === "visible") {
          stadiumAudioRef.current?.play();
        } else {
          stadiumAudioRef.current?.pause();
        }
      }, 50);
    });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <div id="swipeScreen" className="absolute top-0 left-0 w-screen h-screen">
        {/* display */}
        <Display currentText={currentDisplayText} />

        {/* goalie */}
        <Goalie scaleFactor={props.scaleFactor} animation={currentAnimation} />

        {/* card */}
        <CardField scaleFactor={props.scaleFactor} />
        <audio src={stadium} ref={stadiumAudioRef} loop />
      </div>
    </div>
  );
}

SwipeField.whyDidYouRender = true;
export default memo(SwipeField) as any;
